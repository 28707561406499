import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import CloseIcon from "@mui/icons-material/Close";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import SortSvg from "../images/sort_icon.svg";
import {
    FiltersButton,
    SortFormGroup,
    SortIcon,
    DrawerPaper,
    DrawerButton,
    SortByContainer,
    MobileSortLabel,
    SortRadio,
} from "../styles/classes";
import { Theme } from "../styles/theme";
const ProductSorting = ({
    productNames,
    productScores,
    sortScoresHandler,
    sortNamesHandler,
}) => {
    let [sortBy, setSortBy] = useState([]);
    const [openDropDown, setopenDropDown] = useState(false);
    const [drawerState, setDrawerState] = useState(false);
    const sortType = [
        "A - Z",
        "Z - A",
        "Low - high (Score)",
        "High - Low (Score)",
    ];

    useEffect(() => {
        const sortProducts = () => {
            let selectedSort = [];
            switch (sortBy) {
                case (sortBy = "A - Z"):
                    selectedSort = productNames.sort();
                    sortNamesHandler(selectedSort);
                    break;
                case (sortBy = "Z - A"):
                    selectedSort = productNames.sort().reverse();
                    sortNamesHandler(selectedSort);
                    break;
                case (sortBy = "Low - high (Score)"):
                    selectedSort = productScores.sort((a, b) => a - b);
                    sortScoresHandler(selectedSort);
                    break;
                case (sortBy = "High - Low (Score)"):
                    selectedSort = productScores
                        .sort((a, b) => a - b)
                        .reverse();
                    sortScoresHandler(selectedSort);
                    break;
            }
        };
        sortProducts();
    }, [sortBy]);

    const handleClickAway = () => {
        setopenDropDown(false);
    };
    const handleChipTextColor = (condition) => {
        return condition
            ? Theme.palette.primary.contrastText
            : Theme.palette.secondary.main;
    };

    const handleChipBgColor = (condition) => {
        return condition
            ? Theme.palette.primary.main
            : Theme.palette.primary.contrastText;
    };

    const onClickSorting = () => {
        setopenDropDown(!openDropDown);
    };
    const onDrawerToggle = () => {
        setDrawerState(!drawerState);
    };
    const mobileSort = (
        <Grid container direction="row">
            <DrawerButton onClick={onDrawerToggle}>
                <Typography>Sort</Typography>
            </DrawerButton>

            <Drawer
                variant="temporary"
                anchor="bottom"
                open={drawerState}
                onClose={onDrawerToggle}
                ModalProps={{
                    keepMounted: true,
                }}
                PaperProps={{ component: DrawerPaper }}
            >
                <SortByContainer container>
                    <Grid item>
                        <Typography variant="h5" fontWeight={600}>
                            Sort By
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={onDrawerToggle}>
                            <CloseIcon color="secondary" />
                        </IconButton>
                    </Grid>
                </SortByContainer>

                <Grid container direction="row" spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="body" color="grey">
                            Select your sorting
                        </Typography>
                    </Grid>
                    <Grid item>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={sortBy}
                            onChange={(e) => setSortBy(e.target.value)}
                            row
                        >
                            {sortType.map((type, index) => (
                                <MobileSortLabel
                                    value={type}
                                    control={<SortRadio hidden />}
                                    label={type.replace("(Score)", "")}
                                    key={index}
                                    bgcolor={handleChipBgColor(type === sortBy)}
                                    textcolor={handleChipTextColor(
                                        type === sortBy
                                    )}
                                >
                                    <span>{type.replace("(Score)", "")}</span>
                                </MobileSortLabel>
                            ))}
                        </RadioGroup>
                    </Grid>
                </Grid>
            </Drawer>
        </Grid>
    );

    const desktopSort = (
        <Grid container direction="row" spacing={1}>
            <Grid item>
                <FiltersButton
                    variant="outlined"
                    size="medium"
                    onClick={onClickSorting}
                >
                    Sorting
                    <SortIcon src={SortSvg} />
                </FiltersButton>
                {openDropDown && (
                    <ClickAwayListener onClickAway={handleClickAway}>
                        <FormControl component="fieldset" variant="standard">
                            <SortFormGroup>
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={sortBy}
                                    onChange={(e) => setSortBy(e.target.value)}
                                >
                                    {sortType.map((type, index) => (
                                        <FormControlLabel
                                            value={type}
                                            control={<Radio />}
                                            label={type}
                                            key={index}
                                        />
                                    ))}
                                </RadioGroup>
                            </SortFormGroup>
                        </FormControl>
                    </ClickAwayListener>
                )}
            </Grid>
        </Grid>
    );
    return (
        <>
            <Grid container sx={{ display: { xs: "block", sm: "none" } }}>
                {mobileSort}
            </Grid>
            <Grid item xs={2} sx={{ display: { xs: "none", sm: "block" } }}>
                {desktopSort}
            </Grid>
        </>
    );
};

export default ProductSorting;
