import React from "react";
import CardContent from "@mui/material/CardContent";
import CardActionArea from "@mui/material/CardActionArea";
import {
    CardButton,
    CardProduct,
    CardDescriptionText,
    CardProductTitleText,
    CardProductImage,
} from "../styles/classes";
import { OverAllScore } from "./score-component";
import ProductPlaceholder from "../images/no_image.png";

const ProductCard = ({
    productPath,
    productName,
    productDescription,
    productImages,
    productScores,
}) => {
    const productImagelocalFile =
        productImages &&
        productImages[0] &&
        productImages[0].s3Object &&
        productImages[0].s3Object.localFile;

    return (
        <CardProduct>
            <CardActionArea href={productPath}>
                <CardContent>
                    <OverAllScore
                        overall_score={productScores[0].row.overall_score}
                        width="54px"
                        height="54px"
                        position="absolute"
                    />
                    <CardProductImage
                        component="img"
                        image={
                            (productImagelocalFile &&
                                productImagelocalFile.childImageSharp.fixed
                                    .src) ||
                            ProductPlaceholder
                        }
                        alt="product"
                    />
                    <CardProductTitleText gutterBottom component="div">
                        {productName.length > 60
                            ? productName.substring(0, 60) + "..."
                            : productName}
                    </CardProductTitleText>
                    <CardDescriptionText>
                        {productDescription.length > 80
                            ? productDescription.substring(0, 70) + "..."
                            : productDescription}
                    </CardDescriptionText>
                </CardContent>
                <CardButton variant="contained" disableRipple>
                    LEARN MORE
                </CardButton>
            </CardActionArea>
        </CardProduct>
    );
};

export default ProductCard;
