import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Layout from "../components/layout";
import Seo from "../components/seo";
import ProductFilters from "../components/product-filters";
import ProductSorting from "../components/product-sort";
import ProductCard from "../components/product-card";
import Typography from "@mui/material/Typography";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { Theme } from "../styles/theme";
import { FilterContainer } from "../styles/classes";

const productContainsIngredient = (product, ingredientId) => {
    // console.log(`productContainsIngredient(): product: ${product.row.product_name} ingredient_id: ${ingredientId}`);
    return (
        product.links.children.labelIngredients.filter(
            (ingredient) =>
                ingredient.row.ingredient_id === Number(ingredientId)
        ).length > 0
    );
};

const productContainsAllSelectedIngredients = (
    product,
    selectedIngredients
) => {
    // console.log(`productContainsAllSelectedIngredients(): product: ${product.row.product_name} selectedIngredients: ${selectedIngredients}`);
    return selectedIngredients.every((ingredientId) =>
        productContainsIngredient(product, ingredientId)
    );
};

const CompanyPage = ({ pageContext }) => {
    const [filteredProducts, setFilteredProducts] = useState(
        pageContext.products
    );

    const filterProductsEventHandler = (selectedIngredients) => {
        // console.log(`filterProductsEventHandler(): ingredients: ${selectedIngredients}`);
        setFilteredProducts(
            pageContext.products.filter((product) =>
                productContainsAllSelectedIngredients(
                    product,
                    selectedIngredients
                )
            )
        );
    };

    const product_names = [
        ...new Set(filteredProducts.map((product) => product.row.product_name)),
    ];
    const product_scores = [
        ...new Set(
            filteredProducts.map(
                (product_scores) =>
                    product_scores.links.children.productScores[0].row
                        .overall_score
            )
        ),
    ];
    const onSortNamesHandler = () => {
        let sortedNames = [];
        product_names.map((name) =>
            sortedNames.push(
                ...filteredProducts.filter(
                    (product) => name === product.row.product_name
                )
            )
        );
        setFilteredProducts(sortedNames);
    };

    const onSortScoresHandler = () => {
        let sortedScores = [];
        product_scores.map((score) =>
            sortedScores.push(
                ...filteredProducts.filter(
                    (product) =>
                        score ===
                        product.links.children.productScores[0].row
                            .overall_score
                )
            )
        );
        setFilteredProducts(sortedScores);
    };
    return (
        <Layout
            companyName={`${pageContext.companyName}`}
            bgColor={Theme.palette.grayScale.lighterGray}
            companyPath={`${pageContext.companySlug}`}
        >
            <Seo
                title={`${pageContext.companyName}`}
                description={`${pageContext.companyName} Products`}
            />
            <FilterContainer container>
                <Grid item xs={6} sm={6}>
                    <ProductFilters
                        ingredientFilters={pageContext.ingredientFilters}
                        onFilterEventHandler={filterProductsEventHandler}
                    />
                </Grid>
                <Grid item xs={6} sm={6} align="right">
                    <ProductSorting
                        productNames={product_names}
                        productScores={product_scores}
                        sortNamesHandler={onSortNamesHandler}
                        sortScoresHandler={onSortScoresHandler}
                    />
                </Grid>
            </FilterContainer>
            <Grid container spacing={4} sx={{ padding: "1.5rem" }}>
                {filteredProducts.length !== 0 ? (
                    filteredProducts.map((product) => (
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            lg={4}
                            key={product.row.product_name}
                        >
                            <ProductCard
                                productPath={`/${pageContext.companySlug}/${product.row.product_slug}`}
                                productName={product.row.product_name}
                                productDescription={
                                    product.row.product_description
                                }
                                productImages={
                                    product.links.children.productImages
                                }
                                productScores={
                                    product.links.children.productScores
                                }
                            />
                        </Grid>
                    ))
                ) : (
                    <Grid item xs={12} sm={12} md={12} lg={12} align="center">
                        <NotInterestedIcon sx={{ fontSize: 70 }} />
                        <Typography align="center" variant="h5">
                            No Products for this filter found
                        </Typography>
                    </Grid>
                )}
            </Grid>
        </Layout>
    );
};

export default CompanyPage;
