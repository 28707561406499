import React, { useState } from "react";
import Chip from "@mui/material/Chip";
const FilterChip = ({ selected, onChange, name, label }) => {
    const [filterState, setFilterState] = useState(selected);

    const onFilterToggle = () => {
        // console.log (`onFilterToggle(): name=${name} filterState=${filterState}`);
        setFilterState(!filterState);

        // Call the parent's onChange() function, constructing an event that resembles the Checkbox onChange event
        onChange({
            target: {
                name: name,
                checked: !filterState,
            },
        });
    };

    return (
        <Chip
            label={label}
            onClick={onFilterToggle}
            variant={filterState ? "default" : "outlined"}
            color={filterState ? "primary" : "default"}
            size="small"
        />
    );
};

export default FilterChip;
