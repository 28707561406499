import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import CloseIcon from "@mui/icons-material/Close";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import FilterChip from "../components/filter-chip";
import Drawer from "@mui/material/Drawer";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import {
    FiltersButton,
    DrawerPaper,
    FilterFormGroup,
    DrawerButton,
} from "../styles/classes";

const initializeFilterState = (filters) => {
    const initialFilterState = {};
    filters.forEach((filter) => {
        initialFilterState[filter.row.ingredient_id] = false;
    });

    return initialFilterState;
};

const ProductFilters = ({ ingredientFilters, onFilterEventHandler }) => {
    const [filterState, setFilterState] = useState(
        initializeFilterState(ingredientFilters)
    );
    const [drawerState, setDrawerState] = useState(false);
    const [openIngredientsFilter, setOpen] = useState(false);

    useEffect(() => {
        const filterProductList = () => {
            if (onFilterEventHandler) {
                const selectedIngredients = Object.keys(filterState).filter(
                    (key) => filterState[key]
                ); // Keys of all selected ingredient filters
                onFilterEventHandler(selectedIngredients);
            }
        };

        filterProductList();
    }, [filterState]); // Don't pass onFilterEventHandler as dependency, because it will be called on every render

    const onFilterChange = (event) => {
        // console.log (`onFilterChange(): id=${event.target.name} state=${event.target.checked}`);
        setFilterState({
            ...filterState,
            [event.target.name]: event.target.checked,
        });
    };

    const onDrawerToggle = () => {
        setDrawerState(!drawerState);
    };

    const onClickIngrediant = () => {
        setOpen(!openIngredientsFilter);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    const desktopFilters = (
        <Grid container direction="column" spacing={1}>
            <Grid item>
                <FiltersButton
                    variant="outlined"
                    endIcon={
                        openIngredientsFilter ? (
                            <KeyboardArrowUp />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )
                    }
                    size="medium"
                    onClick={onClickIngrediant}
                >
                    Ingredients
                </FiltersButton>
            </Grid>
            {openIngredientsFilter ? (
                <ClickAwayListener onClickAway={handleClickAway}>
                    <FormControl component="fieldset" variant="standard">
                        <FilterFormGroup>
                            {ingredientFilters.map((filter, index) => (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                filterState[
                                                    filter.row.ingredient_id
                                                ]
                                            }
                                            onChange={onFilterChange}
                                            name={`${filter.row.ingredient_id}`}
                                        />
                                    }
                                    label={filter.row.full_name}
                                    key={index}
                                />
                            ))}
                        </FilterFormGroup>
                    </FormControl>
                </ClickAwayListener>
            ) : null}
        </Grid>
    );

    const mobileFilters = (
        <Grid container direction="row">
            <DrawerButton onClick={onDrawerToggle}>
                <Typography>Filter</Typography>
            </DrawerButton>

            <Drawer
                variant="temporary"
                anchor="bottom"
                open={drawerState}
                onClose={onDrawerToggle}
                ModalProps={{
                    keepMounted: true,
                }}
                PaperProps={{ component: DrawerPaper }}
            >
                <Grid container direction="row" justifyContent="space-between">
                    <Grid item xs={8} sx={{ mb: 4, mt: 2 }}>
                        <Typography variant="h5" fontWeight={600}>
                            Filters
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={onDrawerToggle}>
                            <CloseIcon color="secondary" />
                        </IconButton>
                    </Grid>
                </Grid>

                <Grid container direction="row" spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12}>
                        <Typography variant="body" color="gray">
                            Ingredients
                        </Typography>
                    </Grid>
                    {ingredientFilters.map((filter, index) => (
                        <Grid item key={index}>
                            <FilterChip
                                selected={filterState[filter.row.ingredient_id]}
                                onChange={onFilterChange}
                                name={`${filter.row.ingredient_id}`}
                                label={`${filter.row.full_name}`}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Drawer>
        </Grid>
    );

    return (
        <>
            <Grid container sx={{ display: { xs: "block", sm: "none" } }}>
                {mobileFilters}
            </Grid>
            <Grid item xs={2} sx={{ display: { xs: "none", sm: "block" } }}>
                {desktopFilters}
            </Grid>
        </>
    );
};

export default ProductFilters;
